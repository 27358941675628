import React from 'react';
import {
	AUTH_PREFIX_PATH,
	APP_PREFIX_PATH,
	MOBILE_PREFIX_PATH,
	MOBILE_AUTH_PREFIX_PATH,
} from 'configs/AppConfig';
import lazyRetry from 'utils/lazyRetry';

export const publicRoutes = [
	{
		key: 'login',
		path: `${AUTH_PREFIX_PATH}/login`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/auth-views/authentication/login'),
				'login'
			)
		),
	},
	// {
	// 	key: 'register',
	// 	path: `${AUTH_PREFIX_PATH}/register`,
	// 	component: React.lazy(() =>
	// 		import('views/auth-views/authentication/register')
	// 	),
	// },
	{
		key: 'forgot-password',
		path: `${AUTH_PREFIX_PATH}/forgot-password`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/auth-views/authentication/forgot-password'),
				'forgot-password'
			)
		),
	},
	{
		key: 'reset-password',
		path: `${AUTH_PREFIX_PATH}/reset-password`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/auth-views/authentication/reset-password'),
				'reset-password'
			)
		),
	},
	{
		key: 'qr-viewer',
		path: `/public/qr-viewer/:hash`,
		component: React.lazy(() =>
			lazyRetry(() => import('views/mobile-views/qr-viewer'), 'qr-viewer')
		),
	},
];

export const mobilePublicRoutes = [
	{
		key: 'loginMobile',
		path: `${MOBILE_AUTH_PREFIX_PATH}/login`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/auth-views/authentication/login/mobile'),
				'loginMobile'
			)
		),
	},
	{
		key: 'loginMobileUserList',
		path: `${MOBILE_AUTH_PREFIX_PATH}/login/:role`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import('views/auth-views/authentication/login/mobile/UserList'),
				'loginMobileUserList'
			)
		),
	},
	{
		key: 'qr-viewerMobile',
		path: `/public/qr-viewer/:hash`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/mobile-views/qr-viewer'),
				'qr-viewerMobile'
			)
		),
	},
];

export const mobileProtectedRoutes = [
	{
		key: 'moliendaMobile',
		path: `${MOBILE_PREFIX_PATH}/molienda`,
		component: React.lazy(
			() => lazyRetry(() => import('views/mobile-views/molienda')),
			'moliendaMobile'
		),
	},
	{
		key: 'moliendaMobileProceso',
		path: `${MOBILE_PREFIX_PATH}/molienda/:procesoId`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/mobile-views/molienda/MoliendaForm'),
				'moliendaMobileProceso'
			)
		),
	},
	{
		key: 'tecnicoMobile',
		path: `${MOBILE_PREFIX_PATH}/tecnico`,
		component: React.lazy(
			() => lazyRetry(() => import('views/mobile-views/tecnico')),
			'tecnicoMobile'
		),
	},
	{
		key: 'tecnicoMobileAnalito',
		path: `${MOBILE_PREFIX_PATH}/tecnico/:metodoId`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/mobile-views/tecnico/MuestrasAnalito'),
				'tecnicoMobileAnalito'
			)
		),
	},
	{
		key: 'tecnicosStatusMobile',
		path: `${MOBILE_PREFIX_PATH}/tecnicos-status`,
		component: React.lazy(
			() => lazyRetry(() => import('views/mobile-views/tecnicos-status')),
			'tecnicosStatusMobile'
		),
	},
];

export const protectedRoutes = [
	{
		key: 'proceso.id',
		path: `${APP_PREFIX_PATH}/fichas/proceso/:procesoId`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/app-views/fichas/proceso/ProcesoLayout.js'),
				'proceso.id'
			)
		),
	},
	{
		key: 'muestra.id',
		path: `${APP_PREFIX_PATH}/fichas/muestra/:muestraId`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/app-views/fichas/muestra/MuestraLayout.js'),
				'muestra.id'
			)
		),
	},
	{
		key: 'recepcion',
		path: `${APP_PREFIX_PATH}/modulos/recepcion`,
		component: React.lazy(
			() => lazyRetry(() => import('views/app-views/modulos/recepcion')),
			'recepcion'
		),
	},
	{
		key: 'recepcion.lista',
		path: `${APP_PREFIX_PATH}/modulos/recepcion/lista`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/app-views/modulos/recepcion/lista'),
				'recepcion.lista'
			)
		),
	},
	{
		key: 'recepcion.nuevo',
		path: `${APP_PREFIX_PATH}/modulos/recepcion/nuevo`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/app-views/modulos/recepcion/nuevo'),
				'recepcion.nuevo'
			)
		),
	},
	{
		key: 'recepcion.editar',
		path: `${APP_PREFIX_PATH}/modulos/recepcion/editar/:id`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/app-views/modulos/recepcion/editar'),
				'recepcion.editar'
			)
		),
	},
	{
		key: 'molienda',
		path: `${APP_PREFIX_PATH}/modulos/molienda`,
		component: React.lazy(
			() => lazyRetry(() => import('views/app-views/modulos/molienda')),
			'molienda'
		),
	},
	{
		key: 'coordinacion',
		path: `${APP_PREFIX_PATH}/modulos/coordinacion`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/app-views/modulos/coordinacion'),
				'coordinacion'
			)
		),
	},
	{
		key: 'coordinacion.asignacion',
		path: `${APP_PREFIX_PATH}/modulos/coordinacion/asignacion`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/app-views/modulos/coordinacion/asignacion'),
				'coordinacion.asignacion'
			)
		),
	},
	{
		key: 'coordinacion.progreso',
		path: `${APP_PREFIX_PATH}/modulos/coordinacion/progreso`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/app-views/modulos/coordinacion/progreso'),
				'coordinacion.progreso'
			)
		),
	},
	{
		key: 'extraccion',
		path: `${APP_PREFIX_PATH}/modulos/extraccion`,
		component: React.lazy(
			() => lazyRetry(() => import('views/app-views/modulos/extraccion')),
			'extraccion'
		),
	},
	{
		key: 'analisis',
		path: `${APP_PREFIX_PATH}/modulos/analisis`,
		component: React.lazy(
			() => lazyRetry(() => import('views/app-views/modulos/analisis')),
			'analisis'
		),
	},
	{
		key: 'analisis.asignacion',
		path: `${APP_PREFIX_PATH}/modulos/analisis/asignacion`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/app-views/modulos/analisis/asignacion'),
				'analisis.asignacion'
			)
		),
	},
	{
		key: 'analisis.lista',
		path: `${APP_PREFIX_PATH}/modulos/analisis/lista`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/app-views/modulos/analisis/lista'),
				'analisis.lista'
			)
		),
	},
	{
		key: 'resultado',
		path: `${APP_PREFIX_PATH}/modulos/resultado`,
		component: React.lazy(
			() => lazyRetry(() => import('views/app-views/modulos/resultado')),
			'resultado'
		),
	},
	{
		key: 'usuarios',
		path: `${APP_PREFIX_PATH}/administracion/usuarios`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/app-views/administracion/usuarios/lista'),
				'usuarios'
			)
		),
	},
	{
		key: 'usuario.lista',
		path: `${APP_PREFIX_PATH}/administracion/usuarios/lista`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/app-views/administracion/usuarios/lista'),
				'usuario.lista'
			)
		),
	},
	{
		key: 'usuario.nuevo',
		path: `${APP_PREFIX_PATH}/administracion/usuarios/nuevo`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/app-views/administracion/usuarios/nuevo'),
				'usuario.nuevo'
			)
		),
	},
	{
		key: 'establecimiento',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/establecimiento`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import('views/app-views/configuracion/maestros/establecimiento'),
				'establecimiento'
			)
		),
	},
	{
		key: 'establecimiento.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/establecimiento/lista`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import(
						'views/app-views/configuracion/maestros/establecimiento/lista'
					),
				'establecimiento.lista'
			)
		),
	},
	{
		key: 'establecimiento.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/establecimiento/nuevo`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import(
						'views/app-views/configuracion/maestros/establecimiento/nuevo'
					),
				'establecimiento.nuevo'
			)
		),
	},
	{
		key: 'tipo-analisis',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/tipo-analisis`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import('views/app-views/configuracion/maestros/tipo-analisis'),
				'tipo-analisis'
			)
		),
	},
	{
		key: 'tipo-analisis.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/tipo-analisis/lista`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import(
						'views/app-views/configuracion/maestros/tipo-analisis/lista'
					),
				'tipo-analisis.lista'
			)
		),
	},
	{
		key: 'tipo-analisis.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/tipo-analisis/nuevo`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import(
						'views/app-views/configuracion/maestros/tipo-analisis/nuevo'
					),
				'tipo-analisis.nuevo'
			)
		),
	},
	// {
	// 	key: 'especie',
	// 	path: `${APP_PREFIX_PATH}/configuracion/maestros/especie`,
	// 	component: React.lazy(() =>
	// 		import('views/app-views/configuracion/maestros/especie')
	// 	),
	// },
	// {
	// 	key: 'especie.lista',
	// 	path: `${APP_PREFIX_PATH}/configuracion/maestros/especie/lista`,
	// 	component: React.lazy(() =>
	// 		import('views/app-views/configuracion/maestros/especie/lista')
	// 	),
	// },
	// {
	// 	key: 'especie.nuevo',
	// 	path: `${APP_PREFIX_PATH}/configuracion/maestros/especie/nuevo`,
	// 	component: React.lazy(() =>
	// 		import('views/app-views/configuracion/maestros/especie/nuevo')
	// 	),
	// },
	// {
	// 	key: 'grupo-especie',
	// 	path: `${APP_PREFIX_PATH}/configuracion/maestros/grupo-especie`,
	// 	component: React.lazy(() =>
	// 		import('views/app-views/configuracion/maestros/grupo-especie')
	// 	),
	// },
	// {
	// 	key: 'grupo-especie.lista',
	// 	path: `${APP_PREFIX_PATH}/configuracion/maestros/grupo-especie/lista`,
	// 	component: React.lazy(() =>
	// 		import('views/app-views/configuracion/maestros/grupo-especie/lista')
	// 	),
	// },
	// {
	// 	key: 'grupo-especie.nuevo',
	// 	path: `${APP_PREFIX_PATH}/configuracion/maestros/grupo-especie/nuevo`,
	// 	component: React.lazy(() =>
	// 		import('views/app-views/configuracion/maestros/grupo-especie/nuevo')
	// 	),
	// },
	{
		key: 'etapa-cultivo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/etapa-cultivo`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import('views/app-views/configuracion/maestros/etapa-cultivo'),
				'etapa-cultivo'
			)
		),
	},
	{
		key: 'etapa-cultivo.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/etapa-cultivo/lista`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import(
						'views/app-views/configuracion/maestros/etapa-cultivo/lista'
					),
				'etapa-cultivo.lista'
			)
		),
	},
	{
		key: 'etapa-cultivo.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/etapa-cultivo/nuevo`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import(
						'views/app-views/configuracion/maestros/etapa-cultivo/nuevo'
					),
				'etapa-cultivo.nuevo'
			)
		),
	},
	{
		key: 'tipo-establecimiento',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/tipo-establecimiento`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import(
						'views/app-views/configuracion/maestros/tipo-establecimiento'
					),
				'tipo-establecimiento'
			)
		),
	},
	{
		key: 'tipo-establecimiento.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/tipo-establecimiento/lista`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import(
						'views/app-views/configuracion/maestros/tipo-establecimiento/lista'
					),
				'tipo-establecimiento.lista'
			)
		),
	},
	{
		key: 'tipo-establecimiento.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/tipo-establecimiento/nuevo`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import(
						'views/app-views/configuracion/maestros/tipo-establecimiento/nuevo'
					),
				'tipo-establecimiento.nuevo'
			)
		),
	},
	{
		key: 'empresa',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/empresa`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/app-views/configuracion/maestros/empresa'),
				'empresa'
			)
		),
	},
	{
		key: 'empresa.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/empresa/lista`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import('views/app-views/configuracion/maestros/empresa/lista'),
				'empresa.lista'
			)
		),
	},
	{
		key: 'empresa.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/empresa/nuevo`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import('views/app-views/configuracion/maestros/empresa/nuevo'),
				'empresa.nuevo'
			)
		),
	},
	{
		key: 'tipo-muestra',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/tipo-muestra`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/app-views/configuracion/maestros/tipo-muestra'),
				'tipo-muestra'
			)
		),
	},
	{
		key: 'tipo-muestra.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/tipo-muestra/lista`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import(
						'views/app-views/configuracion/maestros/tipo-muestra/lista'
					),
				'tipo-muestra.lista'
			)
		),
	},
	{
		key: 'tipo-muestra.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/tipo-muestra/nuevo`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import(
						'views/app-views/configuracion/maestros/tipo-muestra/nuevo'
					),
				'tipo-muestra.nuevo'
			)
		),
	},
	{
		key: 'tipo-informe',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/tipo-informe`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/app-views/configuracion/maestros/tipo-informe'),
				'tipo-informe'
			)
		),
	},
	{
		key: 'tipo-informe.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/tipo-informe/lista`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import(
						'views/app-views/configuracion/maestros/tipo-informe/lista'
					),
				'tipo-informe.lista'
			)
		),
	},
	{
		key: 'tipo-informe.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/tipo-informe/nuevo`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import(
						'views/app-views/configuracion/maestros/tipo-informe/nuevo'
					),
				'tipo-informe.nuevo'
			)
		),
	},
	{
		key: 'sustancia-prohibida',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/sustancia-prohibida`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import(
						'views/app-views/configuracion/maestros/sustancia-prohibida'
					),
				'sustancia-prohibida'
			)
		),
	},
	{
		key: 'sustancia-prohibida.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/sustancia-prohibida/lista`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import(
						'views/app-views/configuracion/maestros/sustancia-prohibida/lista'
					),
				'sustancia-prohibida.lista'
			)
		),
	},
	{
		key: 'sustancia-prohibida.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/sustancia-prohibida/nuevo`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import(
						'views/app-views/configuracion/maestros/sustancia-prohibida/nuevo'
					),
				'sustancia-prohibida.nuevo'
			)
		),
	},
	{
		key: 'inspector-sernapesca',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/inspector-sernapesca`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import(
						'views/app-views/configuracion/maestros/inspector-sernapesca'
					),
				'inspector-sernapesca'
			)
		),
	},
	{
		key: 'inspector-sernapesca.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/inspector-sernapesca/lista`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import(
						'views/app-views/configuracion/maestros/inspector-sernapesca/lista'
					),
				'inspector-sernapesca.lista'
			)
		),
	},
	{
		key: 'inspector-sernapesca.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/inspector-sernapesca/nuevo`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import(
						'views/app-views/configuracion/maestros/inspector-sernapesca/nuevo'
					),
				'inspector-sernapesca.nuevo'
			)
		),
	},
	{
		key: 'programa',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/programa`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/app-views/configuracion/maestros/programa'),
				'programa'
			)
		),
	},
	{
		key: 'programa.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/programa/lista`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import('views/app-views/configuracion/maestros/programa/lista'),
				'programa.lista'
			)
		),
	},
	{
		key: 'programa.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/programa/nuevo`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import('views/app-views/configuracion/maestros/programa/nuevo'),
				'programa.nuevo'
			)
		),
	},
	{
		key: 'subprograma',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/subprograma`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/app-views/configuracion/maestros/subprograma'),
				'subprograma'
			)
		),
	},
	{
		key: 'subprograma.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/subprograma/lista`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import(
						'views/app-views/configuracion/maestros/subprograma/lista'
					),
				'subprograma.lista'
			)
		),
	},
	{
		key: 'subprograma.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/subprograma/nuevo`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import(
						'views/app-views/configuracion/maestros/subprograma/nuevo'
					),
				'subprograma.nuevo'
			)
		),
	},
	{
		key: 'subprograma.editar',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/subprograma/editar/:id`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import(
						'views/app-views/configuracion/maestros/subprograma/editar'
					),
				'subprograma.editar'
			)
		),
	},
	{
		key: 'personal-empresa',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/personal-empresa`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import(
						'views/app-views/configuracion/maestros/personal-empresa'
					),
				'personal-empresa'
			)
		),
	},
	{
		key: 'personal-empresa.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/personal-empresa/lista`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import(
						'views/app-views/configuracion/maestros/personal-empresa/lista'
					),
				'personal-empresa.lista'
			)
		),
	},
	{
		key: 'personal-empresa.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/personal-empresa/nuevo`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import(
						'views/app-views/configuracion/maestros/personal-empresa/nuevo'
					),
				'personal-empresa.nuevo'
			)
		),
	},
	{
		key: 'almacen',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/almacenes`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/app-views/configuracion/maestros/almacen'),
				'almacen'
			)
		),
	},
	{
		key: 'almacen.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/almacenes/lista`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import('views/app-views/configuracion/maestros/almacen/lista'),
				'almacen.lista'
			)
		),
	},
	{
		key: 'almacen.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/almacenes/nuevo`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import('views/app-views/configuracion/maestros/almacen/nuevo'),
				'almacen.nuevo'
			)
		),
	},
	{
		key: 'matriz',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/matriz`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/app-views/configuracion/maestros/matriz'),
				'matriz'
			)
		),
	},
	{
		key: 'matriz.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/matriz/lista`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/app-views/configuracion/maestros/matriz/lista'),
				'matriz.lista'
			)
		),
	},
	{
		key: 'matriz.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/matriz/nuevo`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/app-views/configuracion/maestros/matriz/nuevo'),
				'matriz.nuevo'
			)
		),
	},
	{
		key: 'metodo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/metodo`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/app-views/configuracion/maestros/metodo'),
				'metodo'
			)
		),
	},
	{
		key: 'metodo.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/metodo/lista`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/app-views/configuracion/maestros/metodo/lista'),
				'metodo.lista'
			)
		),
	},
	{
		key: 'metodo.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/metodo/nuevo`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/app-views/configuracion/maestros/metodo/nuevo'),
				'metodo.nuevo'
			)
		),
	},
	{
		key: 'metodo.editar',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/metodo/editar/:id`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import('views/app-views/configuracion/maestros/metodo/editar'),
				'metodo.editar'
			)
		),
	},
	{
		key: 'analito',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/analito`,
		component: React.lazy(() =>
			lazyRetry(
				() => import('views/app-views/configuracion/maestros/analito'),
				'analito'
			)
		),
	},
	{
		key: 'analito.lista',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/analito/lista`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import('views/app-views/configuracion/maestros/analito/lista'),
				'analito.lista'
			)
		),
	},
	{
		key: 'analito.nuevo',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/analito/nuevo`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import('views/app-views/configuracion/maestros/analito/nuevo'),
				'analito.nuevo'
			)
		),
	},
	{
		key: 'analito.editar',
		path: `${APP_PREFIX_PATH}/configuracion/maestros/analito/editar/:id`,
		component: React.lazy(() =>
			lazyRetry(
				() =>
					import('views/app-views/configuracion/maestros/analito/editar'),
				'analito.editar'
			)
		),
	},
];
