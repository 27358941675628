import { API_BASE_URL } from 'configs/AppConfig';
import { AUTH_TOKEN } from 'constants/AuthConstant';
import qs from 'qs';

const ProcesoService = {};

ProcesoService.fetchProcesos = query => {
	const fetchQuery = qs.stringify(query, { encodeValuesOnly: true });
	return fetch(`${API_BASE_URL}/procesos${query ? '?' + fetchQuery : ''}`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		},
	});
};

ProcesoService.fetchProceso = (id, query) => {
	const fetchQuery = qs.stringify(query, { encodeValuesOnly: true });
	return fetch(
		`${API_BASE_URL}/procesos/${id}${query ? '?' + fetchQuery : ''}`,
		{
			method: 'GET',
			headers: {
				Accept: 'application/json',
				Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
			},
		}
	);
};

ProcesoService.createProceso = dataThunk => {
	const data = dataThunk;

	return fetch(`${API_BASE_URL}/procesos/`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		},
		body: JSON.stringify({
			data: {
				...data,
				etapa: 'molienda',
				horaRecepcion: data.horaRecepcion
					? data.horaRecepcion.format('HH:mm:ss.SSS')
					: null,
				horaMuestreo: data.horaMuestreo
					? data.horaMuestreo.format('HH:mm:ss.SSS')
					: null,
				metodos: data.metodos ? data.metodos.map(row => row[0]) : null,
			},
		}),
	});
};

ProcesoService.editProceso = dataThunk => {
	const { id, data } = dataThunk;

	return fetch(`${API_BASE_URL}/procesos/${id}`, {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		},
		body: JSON.stringify({
			data: {
				...data,
				horaRecepcion: data.horaRecepcion
					? data.horaRecepcion.format('HH:mm:ss.SSS')
					: null,
				horaMuestreo: data.horaMuestreo
					? data.horaMuestreo.format('HH:mm:ss.SSS')
					: null,
				metodos: data.metodos ? data.metodos.map(row => row[0]) : null,
			},
		}),
	});
};

ProcesoService.finishProceso = id => {
	return fetch(`${API_BASE_URL}/procesos/finish/${id}`, {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		},
	});
};

ProcesoService.editBulkProceso = dataThunk => {
	return fetch(`${API_BASE_URL}/procesos-bulk`, {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		},
		body: JSON.stringify(dataThunk),
	});
};

ProcesoService.deleteProceso = dataThunk => {
	const id = dataThunk;

	return fetch(`${API_BASE_URL}/procesos/${id}`, {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
		},
	});
};

export default ProcesoService;
